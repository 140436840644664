
.App
{
	text-align: center;
	padding: 50px;
}

h1
{
	font-size: 22px;
	margin-bottom: 20px;
}

h2
{
	font-size: 16px;
	margin-bottom: 20px;
}

.buttons
{
	padding: 50px;
}

.button
{
	display: inline-block;
	height: 50px;
	line-height: 50px;
	padding: 0px 20px;
	color: #fff;
	background-color: #000;
	text-decoration: none;
	margin: 20px;
	width: 80px;
}
.button.auto
{
	width: auto;
}

html
{
	box-sizing: border-box;
}

body
{
	padding: 0px;
	margin: 0px;
	border: 0px;
	outline: none;

	font-family: "Arial";
	color: #000;
	font-size: 14px;
}

.table
{
	display: table;
	border-spacing: 10px;
	width: 100%;
	max-width: 800px;
	align: center;
	margin: 0 auto;
}
.row, .cell
{
	background-color: #eae8e4;
}
.row
{
	display: table-row;
}
.cell
{
	display: table-cell;
	padding: 20px;
	text-align: left;
	line-height: 20px;
}
.cell.num
{
	text-align: center;
	vertical-align: middle;
	padding: 0px 20px;
	font-size: 20px;
	color: #b3b2b0;
}

.vimeo
{
	width: 100%;
	max-width: 1280px;
	align: center;
	margin: 0 auto;
}

.footer
{
	width: 100%;
	height: 50px;
}

/**
 * Banner
 */

.wp-block-cover-image.has-background-dim:not([class*=-background-color]), .wp-block-cover.has-background-dim:not([class*=-background-color])
{
	background-color: #000;
}

.alignfull
{
	width: 100vw;
	max-width: 100vw;
	margin-left: calc(50% - 50vw);
	padding: 0 15px;
}

.wp-block-cover, .wp-block-cover-image
{
	position: relative;
	background-size: cover;
	background-position: 50%;
	min-height: 430px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1em;
	box-sizing: border-box;
}

.wp-block-cover-image img.wp-block-cover__image-background, .wp-block-cover-image video.wp-block-cover__video-background, .wp-block-cover img.wp-block-cover__image-background, .wp-block-cover video.wp-block-cover__video-background
{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	max-width: none;
	max-height: none;
	-o-object-fit: cover;
	object-fit: cover;
	outline: none;
	border: none;
	box-shadow: none;
}

.wp-block-cover__image-background, .wp-block-cover__video-background
{
	z-index: 0;
}

.alignfull > [class*="__inner-container"], .alignwide > [class*="__inner-container"]
{
	max-width: 718px;
	margin: auto;
}
.wp-block-cover-image .wp-block-cover__inner-container, .wp-block-cover .wp-block-cover__inner-container
{
	width: 100%;
	z-index: 1;
	color: #fff;
}


.wp-block-cover-image.has-background-dim:not(.has-background-gradient):before, .wp-block-cover-image .wp-block-cover__gradient-background, .wp-block-cover.has-background-dim:not(.has-background-gradient):before, .wp-block-cover .wp-block-cover__gradient-background
{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	opacity: .5;
}

.wp-block-cover-image.has-background-dim:before, .wp-block-cover.has-background-dim:before
{
	content: "";
	background-color: inherit;
}

.wp-block-image .aligncenter
{
	margin-left: auto;
	margin-right: auto;
}

.wp-block-image .aligncenter, .wp-block-image .alignleft, .wp-block-image .alignright
{
	display: table;
}

.aligncenter
{
	display: block;
	text-align: center;
	margin: 0 auto;
}
.aligncenter
{
	clear: both;
}

/*.nv-html-content p, blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul
{
	margin: 0;
	padding: 0;
}*/

.wp-block-image {
	margin-bottom: 1em;
}

.wp-block-image:not(.is-style-rounded) img
{
	border-radius: inherit;
}
.wp-block-image img
{
	max-width: 100%;
}
audio, embed, img, object, video
{
	height: auto;
	max-width: 100%;
}
/*img.wp-image-5863
{
	width: 1024px;
	aspect-ratio: auto 1024 / 118;
	height: 118px;
}*/

@media (max-width: 600px)
{
	.App
	{
		padding: 50px 20px;
	}

	.buttons
	{
		padding: 50px 0px;
	}
	.button
	{
		margin: 10px;
		width: 60px;
	}
}

